import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-dark/src/bootstrap-dark.css'
import './index.css';

import 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js'
import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';
import DateTimeUtils from './utils/DateTimeUtils'

import Login from './pages/Login'
import Profile from './pages/Profile'

// DETAILS
import Services from './pages/details/Services';
import Materials from './pages/details/Materials';
import Units from './pages/details/Units'
import Customers from './pages/details/Customers'
import Rooms from './pages/details/Rooms'
import Users from './pages/details/Users'

import Orders from './pages/orders/Orders'
import AddOrder from './pages/orders/AddOrder'
import OrderInfo from './pages/orders/OrderInfo'

import { Container, Navbar, Nav, NavDropdown, Button, Image } from 'react-bootstrap';

import AuthService from './services/AuthService'

import logo from './assets/images/logo.png';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dateTime: ''
    };
  }

  componentDidMount() {
    this.setState({ dateTime: DateTimeUtils.formatDateTime(new Date()) });
  }

  render() {
    // setInterval(() => {
    //   this.setState({ dateTime: DateTimeUtils.formatDateTime(new Date()) });
    // }, 700);
    return (
      <>
        {/* <div className='bg-dark text-center'>
          <Container>
            <p className='mt-2 mb-2'>{this.state.dateTime}</p>
          </Container>
        </div> */}
        {AuthService.isLoggedIn() ? (
          <Navbar expand="lg" className='bg-dark text-white navbar-dark'>
            <Container>
              <Navbar.Brand href="/">
                <Image src={logo} height={64} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="text-white"
                  navbarScroll
                >
                  <Nav.Link href='/orders'>
                    Aufträge
                  </Nav.Link>
                  <NavDropdown color='white' style={{ color: 'white' }} title="Stammdaten" className='text-white' id="navbarScrollingDropdown">
                    <NavDropdown.Item href='/details/customers'>
                      Kunden
                    </NavDropdown.Item>

                    {AuthService.hasRole('admin') ? (
                      <>
                        <NavDropdown.Item href="/details/services">
                          Dienstleistungen
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/details/materials">
                          Materialien
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/details/units">
                          Einheiten
                        </NavDropdown.Item>

                        <NavDropdown.Item href='/details/rooms'>
                          Räume
                        </NavDropdown.Item>
                        <NavDropdown.Item href='/details/users'>Benutzer</NavDropdown.Item>
                      </>
                    ) : null}

                  </NavDropdown>
                  <Nav.Link href='/PROFILE'>
                    Profil
                  </Nav.Link>
                  <Nav.Link onClick={(e) => {
                    e.preventDefault();
                    AuthService.logout();
                    window.location.href = '/';
                  }}>Abmelden</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        ) : null}
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />

            <Route path='/profile' element={<Profile />} />
            <Route path='/details/services' element={<Services />} />
            <Route path='/details/rooms' element={<Rooms />} />
            <Route path='/details/materials' element={<Materials />} />
            <Route path='/details/units' element={<Units />} />
            <Route path='/details/customers' element={<Customers />} />
            <Route path='/details/users' element={<Users />} />


            <Route path='/orders' element={<Orders />} />
            <Route path='/orders/addOrder' element={<AddOrder />} />
            <Route path='/orders/:id' element={<OrderInfo />} />

          </Routes>
        </BrowserRouter>
      </>
    )
  }
}
