import React, { Component } from 'react'
import { Breadcrumb, Button, ButtonGroup, Container, Modal, Pagination, Table } from 'react-bootstrap'

import Products from '../../services/Products'
import PaginationUtils from '../../utils/PaginationUtils'

import { FaInfo, FaPlus, FaTrash } from "react-icons/fa";
import AuthService from '../../services/AuthService';

import DateTimeUtils from '../../utils/DateTimeUtils'
import MaterialDetailsModal from './modals/MaterialDetailsModal'

export default class Materials extends Component {

  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      currentPage: 1,
      records: [],
      numbers: [],
      selectedItem: null,
      modalOpen: false
    }
  }

  componentWillMount() {
    AuthService.checkLoggedInWithRole(['admin']);
  }

  async componentDidMount() {
    var data = await Products.getMaterials();
    var pagination = PaginationUtils.generate(
      this.state.currentPage,
      data,
      5
    );
    this.setState({
      materials: data,
      records: pagination.records.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      numbers: pagination.numbers
    })
  }

  changePage(e, n) {
    e.preventDefault();
    var pagination = PaginationUtils.generate(n, this.state.materials, 5);
    this.setState({
      currentPage: n,
      records: pagination.records.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      numbers: pagination.numbers
    });
  }



  render() {
    return (
      <>
        <Container>
          <h3 className='pt-4'>Materialien ({this.state.materials.length})</h3>
          {AuthService.isAdmin() ? (
            <Button className='mt-3 w-100' onClick={(e) => {
              e.preventDefault();
              this.setState({ modalOpen: true, selectedItem: null });
            }}>
              <FaPlus /> MATERIAL ANLEGEN
            </Button>
          ) : null}
          <Table striped variant='dark' bordered hover className='mt-5 mb-5'>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <td>Beschreibung</td>
                <th>Preis</th>
                <th>Einheit</th>
                <td>Zuletzt Bearbeitet</td>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {this.state.records.map(((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>{item.unitPrice == null ? 0 : item.unitPrice} €</td>
                    <td>{item.unit.name}</td>
                    <td>{DateTimeUtils.formatDateTime(item.lastUpdate)}</td>
                    <td>
                      {/* OpenInfoModal */}
                      <Button onClick={(e) => {
                        this.setState({ modalOpen: true, selectedItem: item });
                      }} className='bg-primary'>
                        <FaInfo />
                      </Button>
                      {AuthService.isAdmin() ? (
                        <Button onClick={async (e) => {
                          e.preventDefault();
                          var confirmation = window.confirm('Willst du wirklich diese Dienstleistung löschen?');
                          if (confirmation) {
                            await Products.deleteMaterial(item.id);
                            var mIndex = this.state.materials.findIndex(o => o.id == item.id);
                            this.state.materials.splice(mIndex, 1);
                            this.state.records.splice(index, 1);

                            this.forceUpdate();
                          }
                        }} style={{ marginLeft: 5 }} className='bg-danger'>
                          <FaTrash />
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                )
              }))}
            </tbody>
          </Table>
          <Pagination>
            {this.state.numbers.map((item, i) => {
              return (
                <Pagination.Item onClick={(e) => this.changePage(e, item)}
                  key={i} active={item == this.state.currentPage}>
                  {item}
                </Pagination.Item>
              )
            })}
          </Pagination>
          <Modal show={this.state.modalOpen} onHide={() => {
            this.setState({ modalOpen: false, selectedItem: null });
          }} background="static" keyboard={false}>
            <MaterialDetailsModal onHide={(e) => {
              e.preventDefault();
              this.setState({ modalOpen: false, selectedItem: null });
            }}
              addOrUpdateMaterial={(e, item) => {
                if (this.state.selectedItem != null) {
                  var findMaterialIndex = this.state.materials.findIndex(o => o.id == item.id);
                  this.state.materials[findMaterialIndex] = item;
                  var recordFindIndex = this.state.records.findIndex(r => r.id == item.id);
                  this.state.records[recordFindIndex] = item;
                  this.forceUpdate();
                } else {
                  this.state.materials.push(item);
                  this.forceUpdate();
                  this.changePage(e, this.state.currentPage);
                }
              }}
              item={this.state.selectedItem} />
          </Modal>
        </Container>
      </>
    )
  }
}