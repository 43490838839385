import React, { Component } from 'react'
import { Breadcrumb, Button, ButtonGroup, Container, Modal, Pagination, Table } from 'react-bootstrap'

import Products from '../../services/Products'
import PaginationUtils from '../../utils/PaginationUtils'

import { FaInfo, FaPlus, FaTrash } from "react-icons/fa";
import AuthService from '../../services/AuthService';

import DateTimeUtils from '../../utils/DateTimeUtils'
import ServiceDetailsModal from './modals/ServiceDetailsModal'

export default class Services extends Component {

  constructor(props) {
    super(props);
    this.state = {
      services: [],
      currentPage: 1,
      records: [],
      numbers: [],
      selectedItem: null,
      modalOpen: false
    }
  }

  componentWillMount() {
    AuthService.checkLoggedInWithRole(['admin']);
  }

  async componentDidMount() {
    var data = await Products.getServices();
    var pagination = PaginationUtils.generate(
      this.state.currentPage,
      data,
      10
    );
    this.setState({
      services: data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      records: pagination.records,
      numbers: pagination.numbers
    })
  }

  changePage(e, n) {
    e.preventDefault();
    var pagination = PaginationUtils.generate(n, this.state.services, 5);
    this.setState({
      currentPage: n,
      records: pagination.records.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      numbers: pagination.numbers
    });
  }


  render() {
    return (
      <>
        <Container>
          <h3 className='pt-4'>Dienstleisitungen ({this.state.services.length})</h3>
          {AuthService.isAdmin() ? (
            <Button className='mt-3 w-100' onClick={(e) => {
              e.preventDefault();
              this.setState({ modalOpen: true, selectedItem: null });
            }}>
              <FaPlus /> DIENSTLEISTUNG ANLEGEN
            </Button>
          ) : null}
          <Table responsive striped variant='dark' bordered hover className='mt-5 mb-5'>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <th>Beschreibung</th>
                <th>Preis</th>
                <td>Einheit</td>
                <th>Zuletzt Bearbeitet</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {this.state.records.map(((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>{item.unitPrice == null ? 0 : item.unitPrice} €</td>
                    <td>{item.unit.name}</td>
                    <td>{DateTimeUtils.formatDateTime(item.lastUpdate)}</td>
                    <td>
                      {/* OpenInfoModal */}
                      <Button onClick={(e) => {
                        this.setState({ modalOpen: true, selectedItem: item });
                      }} className='bg-primary'>
                        <FaInfo />
                      </Button>
                      {AuthService.isAdmin() ? (
                        <Button onClick={async (e) => {
                          e.preventDefault();
                          var confirmation = window.confirm('Willst du wirklich diese Dienstleistung löschen?');
                          if (confirmation) {
                            await Products.deleteService(item.id);
                            var sIndex = this.state.services.findIndex(o => o.id == item.id);
                            this.state.services.splice(sIndex, 1);
                            this.state.records.splice(index, 1);

                            this.forceUpdate();
                          }
                        }} style={{ marginLeft: 5 }} className='bg-danger'>
                          <FaTrash />
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                )
              }))}
            </tbody>
          </Table>
          <Pagination>
            {this.state.numbers.map((item, i) => {
              return (
                <Pagination.Item onClick={(e) => this.changePage(e, item)}
                  key={i} active={item == this.state.currentPage}>
                  {item}
                </Pagination.Item>
              )
            })}
          </Pagination>
          <Modal show={this.state.modalOpen} onHide={() => {
            this.setState({ modalOpen: false, selectedItem: null });
          }} background="static" keyboard={false}>
            <ServiceDetailsModal onHide={(e) => {
              e.preventDefault();
              this.setState({ modalOpen: false, selectedItem: null });
            }}
              addOrUpdateService={(e, item) => {
                if (this.state.selectedItem != null) {
                  var findServiceIndex = this.state.services.findIndex(o => o.id == item.id);
                  this.state.services[findServiceIndex] = item;
                  var findRecordIndex = this.state.records.findIndex(r => r.id == item.id);
                  this.state.records[findRecordIndex] = item;
                  this.forceUpdate();
                } else {
                  this.state.services.push(item);
                  this.forceUpdate();
                  this.changePage(e, this.state.currentPage);
                }
              }}
              item={this.state.selectedItem} />
          </Modal>
        </Container>
      </>
    )
  }
}